.fm_tnb_wrapper {
  margin-top: 64px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.fm_tnb_content {
  width: 100%;
  min-height: 775px;
  display: flex;
}
.fm_tnb_content img{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: center;
}
.fm_img_dark_layer{
  position: relative;
  top: 0;
  left: 0;
  max-width: 608px;
  width: 100%;
  /* height: 100%; */
  background-color: #33333357;
  padding: 50px 20px;
  display: flex;
  align-items: flex-end;
}
.fm_article_infos {
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  color: #FFFFFF;
}
.fm_article_right_state{
  color: #FFD51C;
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 20px;
}
.fm_article_right_title a{
  display: block;
  text-decoration: none;
}
.fm_article_right_title h1 {
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 56px;
  color: #FFFFFF;
  max-width: 693px;
  margin-bottom: 16px;
  transition: all .3s ease;
}
.fm_article_right_date_read{
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 20px;
  color: #FFFFFF;
}
.fm_article_right_read {
  color: #FFD51C;
}


/* hover */
@media (hover:hover) {
  .fm_article_right_title h1:hover{
    opacity: 0.8;
  }
}

/* MEDIA */
@media only screen and (max-width: 1140px) {
  .fm_tnb_content {
    width: calc(100% + 30px);
    margin-left: -15px;
  }
}
@media only screen and (max-width: 1024px) {}

@media only screen and (max-width: 767px) {}

