.fm_filters_wrapper {
  padding: 34px 0;
  width: 100%;
  display: flex;
  justify-content: center;
}
.fm_filters_content {
  width: 100%;
}
.fm_open_filters_small {
  width: 100%;
  background-color: #FFD51C;
  color: #333;
  padding: 14px;
  text-align: center;
  font-family: 'Roboto Mono', monospace;
  font-weight: 400;
  font-size: 16px;
  border-radius: 9px;
  cursor: pointer;
  margin-bottom: 64px;
  transition: all .3s ease;
}
.fm_open_filters_small {
  display: none;
}
.fm_filters_holder{
  height: auto;
  overflow: visible;
  transition: all .3s ease;
}
.fm_filters_holder.fm_filters_open {
  height: auto;
}
.fm_filters_top {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.fm_filters_left {
  flex: 1;
  display: flex;
  gap: 64px;
  margin-right: 64px;
}

.fm_filters_right {
  /* max-width: 576px; */
  max-width: 34%;
  width: 100%;
}


/* filters bottom */
.fm_filters_bot {
  margin-top: 16px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 32px;
}

.fm_filtrers_selected_item {
  max-width: 167px;
  width: 100%;
  padding: 5px 30px 5px 16px;
  color: #333333;
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 16px;
  background-color: #FFD51C;
  border-radius: 16px;
}

.fm_filtrers_selected_item img {
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

/* hover */
@media (hover:hover) {
  .fm_open_filters_small:hover {
    opacity: 0.8;
  }
  .fm_filtrers_selected_item img:hover{ 
    opacity: 0.8;
  }
}


/* MEDIA */
@media only screen and (max-width: 1240px) {
  .fm_filters_left{
    gap: 30px;
    margin-right: 30px;
  }
}

@media only screen and (max-width: 1024px) {
  .fm_filters_left{
    gap: 14px;
    margin-right: 14px;
  }
}

@media only screen and (max-width: 1024px) {
  .fm_filters_top{
    flex-direction: column;
    gap: 16px;
  }
  .fm_filters_left{
    margin-right: 0;
  }
  .fm_filters_right {
    max-width: 100%;
  }
}

@media only screen and (max-width: 680px) {
  .fm_open_filters_small {
    display: block;
  }
  .fm_filters_holder{
    height: 0;
  }
  .fm_filtrers_selected_item{
    max-width: 48%;
    margin-bottom: 2%;
  }
  .fm_filters_left{
    flex-direction: column;
    gap: 16px;
    margin-right: 0;
  }
  .fm_filters_bot {
    gap: initial;
    justify-content: space-between;
  }
}