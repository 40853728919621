.fm_article {
  max-width: calc(50% - 24px);
  width: 100%;
  margin-bottom: 64px;
  border: 4px solid transparent;
  border-radius: 10px;
}
.fm_article:hover {
  border: 4px solid #ffd51b;
}
.fm_article_img {
  width: 100%;
  padding-top: 56.25%;
  margin-bottom: 64px;
}
.fm_article_img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.fm_article_state{
  color: #FFD51C;
  font-weight: 500;
  font-family: 'Roboto Mono', monospace;
  font-size: 20px;
  margin-bottom: 16px;
}
.fm_article_title a {
  color: #fff;
  font-weight: 500;
  font-family: 'Roboto Mono', monospace;
  font-size: 30px;
  text-decoration: none;
  transition: all .3s ease;
}
.fm_article_short_descritpiton{
  color: #fff;
  font-weight: 300;
  font-family: 'Roboto Mono', monospace;
  font-size: 20px;
  margin-bottom: 8px;
  max-height: 128px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fm_article_date{
  color: #fff;
  font-weight: 300;
  font-family: 'Roboto Mono', monospace;
  font-size: 20px;
}
.fm_article_read_time{
  color: #FFD51C;
  font-weight: 300;
  font-family: 'Roboto Mono', monospace;
  font-size: 20px;
}


/* hover */
@media (hover:hover) {
  .fm_article_title a:hover{
    opacity: 0.8;
  }
}

/* MEDIA */
@media only screen and (max-width: 1366px) {
  .fm_article_img {
    margin-bottom: 32px;
  }
  .fm_article_state{
    margin-bottom: 10px;
  }
  .fm_article_title{
    margin-bottom: 16px;
  }
  .fm_article_short_descritpiton{
    font-size: 16px;
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 640px) {
  .fm_article {
    max-width: 100%;
    margin-bottom: 0;
  }
}
