.fm_bio_content {
  width: 100%;
  padding: 40px 0 0;
}

.fm_open_bio_small{
  margin-top: 24px;
  width: 100%;
  background-color: #FFD51C;
  color: #333;
  padding: 14px;
  text-align: center;
  font-family: 'Roboto Mono', monospace;
  font-weight: 400;
  font-size: 16px;
  border-radius: 9px;
  cursor: pointer;
  margin-bottom: 64px;
  transition: all .3s ease;
  display: none;
}

/* bio holder */
.fm_bio_holder {
  height: auto;
  overflow: hidden;
}

/* bio top */
.fm_bio_top {
  display: flex;
}

/* bio top left */
.fm_bio_top_left {
  width: 915px;
}
.fm_bio_img {
  position: absolute;
  top: -40px;
  left: -110px;
  width: 915px;
  height: calc(100% + 40px);
  object-fit: cover;
  object-position: center;
  pointer-events: none;
}
.fm_bio_img_desc {
  color: #fff;
  font-family: 'Covered By Your Grace', cursive;
  font-size: 30px;
  position: absolute;
  bottom: -10px;
  white-space: nowrap;
}
.fm_bio_img_desc span{
  margin-left: 44px;
}

/* bio top right */
.fm_bio_top_right {
  max-width: 1040px;
  width: 100%;
  padding-bottom: 130px;
}

/* name info */
.fm_name_title_infos_holder {
  flex: 1;
  display: flex;
  margin-bottom: 32px;
}
.fm_name_title_infos{
  display: flex;
  align-items: flex-end;
  font-family: 'Palanquin', sans-serif;
  font-weight: 600;
  font-size: 128px;
  color: #FFD51C;
}
.fm_name_title_and {
  margin-right: 11px;
  line-height: 100px;
}
.fm_name_title{
  margin-bottom: 20px;
  line-height: 110px;
}
.fm_name_job {
  font-family: 'Palanquin', sans-serif;
  font-weight: 600;
  font-size: 45px;
  line-height: 45px;
  color: #FFFFFF;
}

/* text */
.fm_bio_text {
  columns: 2;
  gap: 62px;
}
.fm_bio_text p {
  margin-bottom: 30px;
  color: #fff;
  font-family: 'Roboto Mono', monospace;
  font-weight: 400;
  font-size: 16px;
  break-inside: avoid;
}
.fm_bio_text p span {
  color: #FFD51C;
}

/* bio bottom */
.fm_bio_bot {
  display: flex;
  overflow: hidden;
  margin-top: -46px;
}

/* bio bot left */
.fm_bio_bot_left {
  width: 700px;
  padding-top: 214px;
  padding-left: 60px;
  padding-bottom: 160px;
  z-index: 1;
}

/* text */
.fm_bio_bot_left .fm_bio_text {
  columns: 1;
  gap: 0;
}
.fm_bio_bot_left .fm_bio_text p {
  margin-bottom: 64px;
  color: #fff;
  font-family: 'Roboto Mono', monospace;
  font-weight: 400;
  font-size: 16px;
  break-inside: avoid;
  max-width: 447px;
  width: 100%;
}
.fm_bio_bot_left .fm_bio_text p:nth-child(2) {
  /* margin-left: 77px; */
  margin-left: 10%;
}
.fm_bio_bot_left .fm_bio_text p:nth-child(3) {
  /* margin-left: 165px; */
  margin-left: 26%;
  margin-bottom: 30px;
}
.fm_bio_bot_left .fm_bio_text p:nth-child(4) {
  /* margin-left: 165px; */
  margin-left: 26%;
  margin-bottom: 30px;
}
.fm_bio_bot_left .fm_bio_text p:nth-child(5) {
  /* margin-left: 165px; */
  margin-left: 26%;
  margin-bottom: 30px;
}
.fm_bio_bot_left .fm_bio_text p span {
  color: #FFD51C;
}

/* bio bot right */
.fm_bio_bot_right {
  flex: 1;
  z-index: 0;
}

.fm_bio_bot_right .fm_bio_img_map {
  position: absolute;
  top: 0;
  left: -64px;
  width: 1079px;
  object-fit: contain;
  object-position: center;
  pointer-events: none;
}

@media (hover:hover) {
  .fm_open_bio_small:hover {
    opacity: 0.8;
  }
}

/* MEDIA */
@media only screen and (max-width: 1140px) {
  .fm_bio_bot_left{
    padding-left: 0;
    max-width: 580px;
    width: 100%;
  }
  .fm_bio_bot_left .fm_bio_text p,
  .fm_bio_bot_left .fm_bio_text p:nth-child(2),
  .fm_bio_bot_left .fm_bio_text p:nth-child(3),
  .fm_bio_bot_left .fm_bio_text p:nth-child(4),
  .fm_bio_bot_left .fm_bio_text p:nth-child(5){
    margin-left: 0;
  }
}

@media only screen and (max-width: 1024px) {
  .fm_bio_top_left{
    width: 800px;
  }
}

@media only screen and (max-width: 767px) {
  .fm_bio_content{
    padding: 40px 0 64px;
  }
  .fm_open_bio_small{
    display: block;
  }

  /* bio holder */
  .fm_bio_holder {
    height: auto;
  }
  .fm_bio_holder.fm_hide_bio {
    height: 0;
  }

  .fm_bio_top_left{
    display: none;
  }
  .fm_bio_bot_right{
    display: none;
  }
  .fm_name_title_infos_holder {
    display: none;
  }
  .fm_bio_text{
    columns: 1;
  }
  .fm_bio_top_right{
    padding-bottom: 0;
  }
  .fm_bio_bot_left{
    padding-top: 0;
  }
  .fm_bio_bot{
    margin-top: 0;
  }
  .fm_bio_bot_left{
    max-width: 100%;
    padding-bottom: 64px;
  }
  .fm_bio_bot_left .fm_bio_text p {
    max-width: 100%;
    margin-bottom: 30px;
  }
}