.fm_articles_holder {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 60px;
}



/* hover */
@media (hover:hover) {
 
}

/* MEDIA */
@media only screen and (max-width: 640px) {
  .fm_articles_holder{
    flex-direction: column;
    gap: 64px;
  }
}