.fm_press_content {
  width: 100%;
  padding: 64px 0 128px;
}

.fm_press_articles {
  display: flex;
  justify-content: space-between;
  gap: 46px;
  margin-bottom: 120px;
}

/* single item */
.fm_press_single_item_holder {
  max-width: 48%;
  width: 100%;
}
.fm_single_item {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
}
.fm_single_item img{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.fm_singl_dark_layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 285px;
  height: 100%;
  background-color: #33333357;
  filter: brightness(-4);
}
.fm_press_single_infos {
  padding: 50px 6%;
  color: #fff;
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
}
.fm_press_single_state,
.fm_press_single_read_time {
  font-size: 20px;
  color: #FFD51C;
}
.fm_press_single_title,
.fm_press_single_title a {
  font-size: 56px;
  margin-bottom: 16px;
  max-width: 696px;
  color: inherit;
  text-decoration: none;
  transition: all .3s ease;
}
.fm_press_single_date {
  font-size: 20px;
}

/* multi items */
.fm_press_items {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.fm_press_item {
  display: flex;
  gap: 32px;
  width: 100%;
  min-height: 256px;
  margin-bottom: 30px;
}
.fm_press_item:last-of-type {
  margin-bottom: 0;
}
.fm_press_item_image_holder {
  max-width: 392px;
  width: 100%;
}
.fm_press_item_image {
  width: 100%;
  height: 100%;
}
.fm_press_item_image img{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.fm_press_item_infos {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
}
.fm_press_item_state {
  font-size: 20px;
  color: #FFD51C;
}
.fm_press_item_title,
.fm_press_item_title a {
  font-size: 30px;
  color: inherit;
  text-decoration: none;
  transition: all .3s ease;
}
.fm_press_item_date {
  font-size: 20px;
  font-weight: 300;
}
.fm_press_item_read_time{
  font-size: 20px;
  color: #FFD51C;
  font-weight: 300;
}

.fm_see_all_articles {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.fm_see_all_articles h2 {
  font-family: 'Covered By Your Grace', cursive;
  font-size: 49px;
  color: #fff;
  margin-bottom: 12px;
}
.fm_see_all_articles img{
  margin-bottom: 38px;
}
.fm_see_all_articles a {
  padding: 21px 28px;
  background-color: #FFD51C;
  color: #333333;
  font-family: 'Roboto Mono', monospace;
  font-weight: 400;
  font-size: 25px;
  border-radius: 17px;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 6px;
  transition: all .3s ease;
}


/* hover */
@media (hover:hover) {
  .fm_press_single_title:hover{
    opacity: 0.8;
  }
  .fm_see_all_articles a:hover{
    opacity: 0.8;
  }
}

/* MEDIA */
@media only screen and (max-width: 1140px) {
  .fm_press_articles {
    flex-direction: column;
  }
  .fm_press_single_item_holder {
    max-width: 100%;
  }
}

@media only screen and (max-width: 680px) {
  .fm_press_items {
    gap: 64px;
  }
  .fm_press_item {
    flex-direction: column;
    margin-bottom: 0;
  }
  .fm_press_item_image_holder {
    max-width: 100%;
  }
  .fm_press_item_image {
    padding-top: 56.25%;
  }

  .fm_see_all_articles h2 {
    font-size: 6vw;
  }
  .fm_see_all_articles img{
    width: 100%;
  }
}