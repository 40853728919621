@import url('https://fonts.googleapis.com/css2?family=Covered+By+Your+Grace&family=Palanquin:wght@400;600&family=Roboto+Mono:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&family=Sora:wght@300;700&display=swap');

/* font-family: 'Covered By Your Grace', cursive;

font-family: 'Palanquin', sans-serif;

font-family: 'Roboto Mono', monospace;

font-family: 'Sora', sans-serif; */

* {
  box-sizing: border-box;
  position: relative;
  transition: all 0.3s ease;
}


body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  /* font-family: 'Covered By Your Grace', cursive; */
}

main {
  width: 100%;
  min-height: calc(var(--vh, 1vh) * 100);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.fm_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.fm_container {
  max-width: 1730px;
  width: 100%;
  padding: 0 15px;
}

#fm_homepage {
  background: #000000;
}
#fm_mwaj {
  background: #333333;
  padding-top: 130px;
}
#fm_article,
#fm_blog {
  background: #E6E6E6;
}


/* MEDIA */
@media only screen and (max-width: 767px) {
  .sxl_container {
    padding: 0;  
  }
}
