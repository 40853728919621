.fm_footer {
  display: flex;
  justify-content: center;
  background-color: #000000;
  padding: 128px 0 75px;
}

.fm_footer_content {
  display: flex;
  justify-content: space-between;
  gap: 34px;
}

.fm_footer_left {
  max-width: 32%;
  width: 100%;
}
.fm_footer_col {
  color: #FBFDFF;
}

.fm_footer_col .fm_footer_col_title {
  margin-bottom: 36px;
  font-family: 'Sora', sans-serif;
  font-weight: 700;
  font-size: 16px;
}
.fm_footer_col p {
  font-family: 'Sora', sans-serif;
  font-weight: 300;
  font-size: 16px;
  margin-bottom: 40px;
}
.fm_footer_col p:last-of-type{
  margin-bottom: 0;
}
.fm_footer_col ul{
  padding: 0;
  margin: 0;
  list-style: none;
  color: #FBFDFF;
}
.fm_footer_col ul li {
  margin-bottom: 36px;
}
.fm_footer_col ul li:last-of-type {
  margin-bottom: 0;
}
.fm_footer_col a {
  font-family: 'Sora', sans-serif;
  font-weight: 300;
  font-size: 16px;
  color: #FBFDFF;
  text-decoration: none;
  transition: all .3s ease;
}

.fm_footer_right {
  flex: 1;
  display: flex;
  justify-content: space-around;
}
.fm_footer_col_socials {
  margin-bottom: 38px;
}
.fm_footer_col_socials a {
  margin-right: 8px;
}
.fm_footer_col_socials a:last-of-type{
  margin-right: 0;
}

@media (hover: hover){
  .fm_footer_col a:hover {
    opacity:  0.8;
  }
}

@media only screen and (max-width: 1100px) {
  .fm_footer_content {
    flex-direction: column;
    gap: 64px;
  }
  .fm_footer_left{
    max-width: 100%;
  }
}

@media only screen and (max-width: 640px) {
  .fm_footer{
    padding-top: 64px;
  }
  .fm_footer_right{
    flex-direction: column;
    gap: 32px;
  }
  .fm_footer_col ul li{
    margin-bottom: 16px;
  }
  .fm_footer_col .fm_footer_col_title{
    margin-bottom: 16px;
  }
  .fm_footer_col_socials{
    margin-bottom: 18px;
  }
}
