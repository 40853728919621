.fm_mwaj_wrapper {
  width: 100%;
  background-color: #FFD51C;
  display: flex;
  justify-content: center;
  padding-bottom: 16px;
}
.fm_mwaj_content {
  padding-left: 125px;
}
.fm_mwaj_title {
  margin-top: -58px;
  margin-bottom: 32px;
  display: flex;
  align-items: flex-start;
  font-family: 'Roboto Mono', monospace;
  font-weight: 700;
  font-size: 60px;
  line-height: 60px;
  color: #fff;
  white-space: nowrap;
}
.fm_mwaj_title_l {
  margin-right: 34px;
}
.fm_mwaj_title .fm_mwaj_title_r_bot {
  color: #333333;
}

.fm_mwaj_text_content {
  display: flex;
  align-items: flex-start;
}
.fm_mwaj_text_btn {
  /* max-width: 828px; */
  max-width: 55%;
  width: 100%;
  margin-right: 24px;
}
.fm_mwaj_text_btn p {
  font-family: 'Roboto Mono', monospace;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  margin-bottom: 10px;
}
.fm_mwaj_btn {
  display: flex;
  align-items: center;
}
.fm_mwaj_btn p {
 font-size: 30px;
 color: #333333;
 font-family: 'Covered By Your Grace', cursive;
 margin-bottom: 0;
}
.fm_mwaj_btn img {
  display: block;
  margin: 0 28px;
}
.fm_mwaj_btn a {
  padding: 12px 16px;
  background-color: #333333;
  color: #fff;
  font-family: 'Roboto Mono', monospace;
  font-weight: 400;
  font-size: 15px;
  border-radius: 17px;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 6px;
  white-space: nowrap;
  transition: all .3s ease;
}

.fm_mwaj_logo_holder {
  flex: 1;
  display: flex;
  justify-content: center;
}
.fm_mwaj_logo_holder a{
  max-width: 415px;
  width: 100%;
}
.fm_mwaj_logo_holder img {
  display: block;
  max-width: 415px;
  width: 100%;
}

/* HOVER */
@media (hover:hover) {
  .fm_mwaj_btn a:hover{
    opacity: 0.8;
  }
}

/* MEDIA */
@media only screen and (max-width: 1140px) {
  .fm_mwaj_content{
    padding-left: 60px;
  }
  .fm_mwaj_logo_holder a{
    max-width: 80%;
  }
  .fm_mwaj_logo_holder img {
    max-width: 80%;
  }
}
@media only screen and (max-width: 1024px) {
  .fm_mwaj_content{
    padding-left: 0;
  }
  .fm_mwaj_logo_holder a{
    max-width: 94%;
  }
  .fm_mwaj_logo_holder img {
    max-width: 94%;
  }
}

@media only screen and (max-width: 939px) {
  .fm_mwaj_title{
    font-size: 41px;
  }
  .fm_mwaj_title_r {
    font-size: 41px;
  }
}

@media only screen and (max-width: 767px) {
  .fm_mwaj_text_btn {
    max-width: 100%;
    margin-right: 0;
  }
  .fm_mwaj_logo_holder {
    display: none;
  }
  .fm_mwaj_btn {
    display: none;
  }
}

@media only screen and (max-width: 660px) {
  .fm_mwaj_title{
    flex-direction: column;
    margin-top: -80px;
  }
  .fm_mwaj_title_r {
    font-size: 18px;
    line-height: 18px;
  }
}