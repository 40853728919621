.fm_article_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
.fm_article_container {
  max-width: 1810px;
  width: 100%;
  padding: 0 15px;
}
.fm_article_content {
  display: flex;
  gap: 70px;
}

/* article left */
.fm_article_left {
  width: 43%;
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  margin-left: -15px;
  z-index: 99;
}

.fm_article_img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.fm_article_back_language {
  position: absolute;
  left: 64px;
  right: 64px;
  top: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fm_back_arrow {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #E6E6E6;
  font-family: 'Roboto Mono', monospace;
  font-weight: 400;
  font-size: 25px;
  cursor: pointer;
  transition: all .3s ease;
}
.fm_back_language {
  background-color: #FFD51C;
  color: #333333;
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 16px;
  padding: 5px 28px;
  border-radius: 16px;
}

.fm_article_infos {
  position: absolute;
  bottom: 32px;
  left: 0;
  width: 100%;
  background-color: #33333357;
  filter: brightness(-4);
  padding: 7px 27px 7px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fm_article_info{
  font-family: 'Covered By Your Grace', cursive;
  font-size: 30px;
  color: #FFFFFF;
  margin-right: 24px;
}
.fm_article_state span {
  color: #FFD51C;
}

.fm_article_share {
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 16px;
  color: #FFFFFF;
  text-align: right;
}
.fm_article_share_title {
  margin-bottom: 16px;
}
.fm_article_share_icons {
  display: flex;
  gap: 12px;
  justify-content: flex-end;
}

/* article right */
.fm_article_right {
  flex: 1;
  padding-top: 60px;
  padding-bottom: 20px;
  width: calc(100% - (43% + 70px));
  padding-left: calc(43% + 70px);
}
.fm_article_right_state{
  color: #FFD51C;
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 21px;
}
.fm_article_right_title h1 {
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 56px;
  color: #333333;
  max-width: 693px;
  margin-bottom: 46px;
}
.fm_article_right_date_read{
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 46px;
  color: #333333;
}
.fm_article_right_author{
  font-family: 'Roboto Mono', monospace;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  margin-bottom: 20px;
}
.fm_article_right_content {
  columns: 2;
  column-gap: 55px;
  margin-bottom: 43px;
}
.fm_article_right_content p{
  font-family: 'Roboto Mono', monospace;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  margin-bottom: 20px;
  break-inside: avoid;
}

/* CAROUSEL */
.fm_carousel_holder {
  width: 100%;
  padding-top: 40px;
}

/* hover */
@media (hover:hover) {
  .fm_back_arrow:hover {
    opacity: 0.8;
  }
  .fm_article_share_icons img:hover{
    opacity: 0.8;
  }
}

/* MEDIA */
@media only screen and (max-width: 1920px) {
  .fm_article_wrapper {
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 1730px) {
  .fm_article_wrapper {
    justify-content: center;
  }
  .fm_article_container {
    max-width: 1730px;
  }
}

@media only screen and (max-width: 1366px) {
  .fm_article_content {
    gap: 34px;
  }
  .fm_article_right_content {
    columns: 1;
  }
}
@media only screen and (max-width: 1140px) {
  .fm_article_content {
    gap: 64px;
    flex-direction: column;
  }
  /* article left */
  .fm_article_left {
    width: calc(100% + 30px);
  }
  .fm_article_back_language{
    left: 15px;
  }
  .fm_back_arrow span,
  .fm_back_language{
    display: none;
  }
  .fm_back_arrow img {
    width: 34px;
  }
  .fm_article_infos{
    padding-right: 15px;
    padding-left: 15px;
  }
  .fm_article_info{
    font-size: 16px; 
  }
  .fm_article_share{
    font-size: 10px;
    text-align: center;
  }
  .fm_article_share_icons{
    justify-content: center;
  }
  .fm_article_share_title{
    margin-bottom: 6px;
  }
  .fm_article_share_icons a img{
    width: 15px;
  }

  /* article right */
  .fm_article_right{
    width: 100%;
    padding-top: 0;
  }
  .fm_article_right_state {
    margin-bottom: 0;
  }
  .fm_article_right_title h1 {
    line-height: 55px;
  }
}