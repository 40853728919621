@import url(https://fonts.googleapis.com/css2?family=Covered+By+Your+Grace&family=Palanquin:wght@400;600&family=Roboto+Mono:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&family=Sora:wght@300;700&display=swap);
/* font-family: 'Covered By Your Grace', cursive;

font-family: 'Palanquin', sans-serif;

font-family: 'Roboto Mono', monospace;

font-family: 'Sora', sans-serif; */

* {
  box-sizing: border-box;
  position: relative;
  transition: all 0.3s ease;
}


body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  /* font-family: 'Covered By Your Grace', cursive; */
}

main {
  width: 100%;
  min-height: calc(1vh * 100);
  min-height: calc(var(--vh, 1vh) * 100);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.fm_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.fm_container {
  max-width: 1730px;
  width: 100%;
  padding: 0 15px;
}

#fm_homepage {
  background: #000000;
}
#fm_mwaj {
  background: #333333;
  padding-top: 130px;
}
#fm_article,
#fm_blog {
  background: #E6E6E6;
}


/* MEDIA */
@media only screen and (max-width: 767px) {
  .sxl_container {
    padding: 0;  
  }
}

.Heading_fm_heading_content__1CIlb {
  width: 100%;
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  align-items: center;
  padding: 72px 0 14px;
}

/* heading left */
.Heading_fm_heading_left__3hiu2 {
  max-width: 34%;
  width: 100%;
  margin-right: 24px;
  height: 100%;
  overflow: hidden;
  padding-bottom: 36px;
}
.Heading_fm_heading_image_holder__pqEbG {
  position: relative;
  height: 100%;
  padding: 0 30px;
  overflow: hidden;
}
.Heading_fm_heading_img_bg__3-bCk {
  position: absolute;
  top: 93px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  object-fit: cover;
  object-position: center;
  height: 100%;
}

.Heading_fm_heading_img_holder__2AC8A {
  padding-bottom: 74px;
  width: 100%;
  height: 100%;
}
.Heading_fm_heading_img__17A0L {
  display: block;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  object-fit: cover;
  object-position: top;
}

/* heading right */
.Heading_fm_heading_right__1AQbl{
  flex: 1 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.Heading_fm_name_title_infos_holder__1HyGR {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Heading_fm_name_title_infos__3VMio{
  display: flex;
  align-items: flex-end;
  font-family: 'Palanquin', sans-serif;
  font-weight: 600;
  font-size: 128px;
  color: #FFD51C;
}
.Heading_fm_name_title_and__1jepY {
  margin-right: 11px;
  line-height: 100px;
}
.Heading_fm_name_title__SZ_va{
  margin-bottom: 20px;
  line-height: 110px;
}
.Heading_fm_name_job__3-7d8 {
  font-family: 'Palanquin', sans-serif;
  font-weight: 600;
  font-size: 45px;
  line-height: 45px;
  color: #FFFFFF;
}

/* socials */
.Heading_fm_social_networks_logo_socials__35e-p {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.Heading_fm_social_networks_logo__3X44y{
  margin-right: 35px;
}
.Heading_fm_social_networks_logo__3X44y img {
  width: 170px;
  display: block;
  z-index: 3;
}
.Heading_fm_socials_holder__3KQm1 {
  z-index: 3;
}
.Heading_fm_socials_text__1Im8M{
  color: #FFFFFF;
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 16px;
  text-align: center;
}
.Heading_fm_socials__1W174 {
  display: flex;
}
.Heading_fm_socials__1W174 a {
  display: inline-block;
  margin-right: 12px;
}
.Heading_fm_socials__1W174 a:last-of-type {
  margin-right: 0;
}
.Heading_fm_socials__1W174 a img{
  width: 29px;
  display: block;
}


/* scroll */
.Heading_fm_scroll_icon_holder__1RW2X{
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 2;
  color: #FFD51C;
  font-family: 'Sora', sans-serif;
  font-size: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 14px;
}
.Heading_fm_scroll_icon_holder__1RW2X img {
  margin-bottom: 8px;
}

/* MEDIA */
@media only screen and (max-width: 1140px) {
  /* heading left */
  .Heading_fm_heading_left__3hiu2 {
    height: auto;
  }
  .Heading_fm_heading_img_holder__2AC8A{
    padding-bottom: 30px;
  }

  /* heading right */
  .Heading_fm_name_title_infos__3VMio{
    font-size: 80px;
  }
  .Heading_fm_name_title_and__1jepY,
  .Heading_fm_name_title__SZ_va{
    line-height: 70px;
  }
  .Heading_fm_name_job__3-7d8{
    font-size: 28px;
    line-height: 34px;
  }
}

@media only screen and (max-width: 767px) {
  .Heading_fm_heading_content__1CIlb {
    flex-direction: column-reverse;
    justify-content: center;
  }
  
  /* heading left */
  .Heading_fm_heading_left__3hiu2{
    margin-right: 0;
    max-width: 275px;
    width: 100%;
  }

  /* heading right */
  .Heading_fm_heading_right__1AQbl{
    height: auto;
    flex: auto;
    margin-bottom: 34px;
  }
  .Heading_fm_name_title_infos__3VMio{
    font-size: 54px;
  }
  .Heading_fm_name_title_and__1jepY,
  .Heading_fm_name_title__SZ_va{
    line-height: 48px;
  }
  .Heading_fm_name_job__3-7d8{
    font-size: 19px;
    line-height: 24px;
  }
  .Heading_fm_social_networks_logo_socials__35e-p{
    display: none;
  }
  .Heading_fm_scroll_icon_holder__1RW2X{
    display: none;
  }
}
.Bio_fm_bio_content__2qlpT {
  width: 100%;
  padding: 40px 0 0;
}

.Bio_fm_open_bio_small__1YHlF{
  margin-top: 24px;
  width: 100%;
  background-color: #FFD51C;
  color: #333;
  padding: 14px;
  text-align: center;
  font-family: 'Roboto Mono', monospace;
  font-weight: 400;
  font-size: 16px;
  border-radius: 9px;
  cursor: pointer;
  margin-bottom: 64px;
  transition: all .3s ease;
  display: none;
}

/* bio holder */
.Bio_fm_bio_holder__3yv0m {
  height: auto;
  overflow: hidden;
}

/* bio top */
.Bio_fm_bio_top__1PKkh {
  display: flex;
}

/* bio top left */
.Bio_fm_bio_top_left__12DNc {
  width: 915px;
}
.Bio_fm_bio_img__1USoK {
  position: absolute;
  top: -40px;
  left: -110px;
  width: 915px;
  height: calc(100% + 40px);
  object-fit: cover;
  object-position: center;
  pointer-events: none;
}
.Bio_fm_bio_img_desc__BxHF1 {
  color: #fff;
  font-family: 'Covered By Your Grace', cursive;
  font-size: 30px;
  position: absolute;
  bottom: -10px;
  white-space: nowrap;
}
.Bio_fm_bio_img_desc__BxHF1 span{
  margin-left: 44px;
}

/* bio top right */
.Bio_fm_bio_top_right__1Jwdn {
  max-width: 1040px;
  width: 100%;
  padding-bottom: 130px;
}

/* name info */
.Bio_fm_name_title_infos_holder__2nf5G {
  flex: 1 1;
  display: flex;
  margin-bottom: 32px;
}
.Bio_fm_name_title_infos__oVdXo{
  display: flex;
  align-items: flex-end;
  font-family: 'Palanquin', sans-serif;
  font-weight: 600;
  font-size: 128px;
  color: #FFD51C;
}
.Bio_fm_name_title_and__2GKd- {
  margin-right: 11px;
  line-height: 100px;
}
.Bio_fm_name_title__1rz1h{
  margin-bottom: 20px;
  line-height: 110px;
}
.Bio_fm_name_job__3w8nl {
  font-family: 'Palanquin', sans-serif;
  font-weight: 600;
  font-size: 45px;
  line-height: 45px;
  color: #FFFFFF;
}

/* text */
.Bio_fm_bio_text__1tIUO {
  -webkit-columns: 2;
          columns: 2;
  grid-gap: 62px;
  gap: 62px;
}
.Bio_fm_bio_text__1tIUO p {
  margin-bottom: 30px;
  color: #fff;
  font-family: 'Roboto Mono', monospace;
  font-weight: 400;
  font-size: 16px;
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
          break-inside: avoid;
}
.Bio_fm_bio_text__1tIUO p span {
  color: #FFD51C;
}

/* bio bottom */
.Bio_fm_bio_bot__1ZGZI {
  display: flex;
  overflow: hidden;
  margin-top: -46px;
}

/* bio bot left */
.Bio_fm_bio_bot_left__1XCmM {
  width: 700px;
  padding-top: 214px;
  padding-left: 60px;
  padding-bottom: 160px;
  z-index: 1;
}

/* text */
.Bio_fm_bio_bot_left__1XCmM .Bio_fm_bio_text__1tIUO {
  -webkit-columns: 1;
          columns: 1;
  grid-gap: 0;
  gap: 0;
}
.Bio_fm_bio_bot_left__1XCmM .Bio_fm_bio_text__1tIUO p {
  margin-bottom: 64px;
  color: #fff;
  font-family: 'Roboto Mono', monospace;
  font-weight: 400;
  font-size: 16px;
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
          break-inside: avoid;
  max-width: 447px;
  width: 100%;
}
.Bio_fm_bio_bot_left__1XCmM .Bio_fm_bio_text__1tIUO p:nth-child(2) {
  /* margin-left: 77px; */
  margin-left: 10%;
}
.Bio_fm_bio_bot_left__1XCmM .Bio_fm_bio_text__1tIUO p:nth-child(3) {
  /* margin-left: 165px; */
  margin-left: 26%;
  margin-bottom: 30px;
}
.Bio_fm_bio_bot_left__1XCmM .Bio_fm_bio_text__1tIUO p:nth-child(4) {
  /* margin-left: 165px; */
  margin-left: 26%;
  margin-bottom: 30px;
}
.Bio_fm_bio_bot_left__1XCmM .Bio_fm_bio_text__1tIUO p:nth-child(5) {
  /* margin-left: 165px; */
  margin-left: 26%;
  margin-bottom: 30px;
}
.Bio_fm_bio_bot_left__1XCmM .Bio_fm_bio_text__1tIUO p span {
  color: #FFD51C;
}

/* bio bot right */
.Bio_fm_bio_bot_right__3ZNo2 {
  flex: 1 1;
  z-index: 0;
}

.Bio_fm_bio_bot_right__3ZNo2 .Bio_fm_bio_img_map__1SIPb {
  position: absolute;
  top: 0;
  left: -64px;
  width: 1079px;
  object-fit: contain;
  object-position: center;
  pointer-events: none;
}

@media (hover:hover) {
  .Bio_fm_open_bio_small__1YHlF:hover {
    opacity: 0.8;
  }
}

/* MEDIA */
@media only screen and (max-width: 1140px) {
  .Bio_fm_bio_bot_left__1XCmM{
    padding-left: 0;
    max-width: 580px;
    width: 100%;
  }
  .Bio_fm_bio_bot_left__1XCmM .Bio_fm_bio_text__1tIUO p,
  .Bio_fm_bio_bot_left__1XCmM .Bio_fm_bio_text__1tIUO p:nth-child(2),
  .Bio_fm_bio_bot_left__1XCmM .Bio_fm_bio_text__1tIUO p:nth-child(3),
  .Bio_fm_bio_bot_left__1XCmM .Bio_fm_bio_text__1tIUO p:nth-child(4),
  .Bio_fm_bio_bot_left__1XCmM .Bio_fm_bio_text__1tIUO p:nth-child(5){
    margin-left: 0;
  }
}

@media only screen and (max-width: 1024px) {
  .Bio_fm_bio_top_left__12DNc{
    width: 800px;
  }
}

@media only screen and (max-width: 767px) {
  .Bio_fm_bio_content__2qlpT{
    padding: 40px 0 64px;
  }
  .Bio_fm_open_bio_small__1YHlF{
    display: block;
  }

  /* bio holder */
  .Bio_fm_bio_holder__3yv0m {
    height: auto;
  }
  .Bio_fm_bio_holder__3yv0m.Bio_fm_hide_bio__1kdn- {
    height: 0;
  }

  .Bio_fm_bio_top_left__12DNc{
    display: none;
  }
  .Bio_fm_bio_bot_right__3ZNo2{
    display: none;
  }
  .Bio_fm_name_title_infos_holder__2nf5G {
    display: none;
  }
  .Bio_fm_bio_text__1tIUO{
    -webkit-columns: 1;
            columns: 1;
  }
  .Bio_fm_bio_top_right__1Jwdn{
    padding-bottom: 0;
  }
  .Bio_fm_bio_bot_left__1XCmM{
    padding-top: 0;
  }
  .Bio_fm_bio_bot__1ZGZI{
    margin-top: 0;
  }
  .Bio_fm_bio_bot_left__1XCmM{
    max-width: 100%;
    padding-bottom: 64px;
  }
  .Bio_fm_bio_bot_left__1XCmM .Bio_fm_bio_text__1tIUO p {
    max-width: 100%;
    margin-bottom: 30px;
  }
}
.MyWorkAsJournalist_fm_mwaj_wrapper__1lmwF {
  width: 100%;
  background-color: #FFD51C;
  display: flex;
  justify-content: center;
  padding-bottom: 16px;
}
.MyWorkAsJournalist_fm_mwaj_content__1t-2B {
  padding-left: 125px;
}
.MyWorkAsJournalist_fm_mwaj_title__2WwWF {
  margin-top: -58px;
  margin-bottom: 32px;
  display: flex;
  align-items: flex-start;
  font-family: 'Roboto Mono', monospace;
  font-weight: 700;
  font-size: 60px;
  line-height: 60px;
  color: #fff;
  white-space: nowrap;
}
.MyWorkAsJournalist_fm_mwaj_title_l__1jdUd {
  margin-right: 34px;
}
.MyWorkAsJournalist_fm_mwaj_title__2WwWF .MyWorkAsJournalist_fm_mwaj_title_r_bot__3ym63 {
  color: #333333;
}

.MyWorkAsJournalist_fm_mwaj_text_content__3CjlZ {
  display: flex;
  align-items: flex-start;
}
.MyWorkAsJournalist_fm_mwaj_text_btn__unA9h {
  /* max-width: 828px; */
  max-width: 55%;
  width: 100%;
  margin-right: 24px;
}
.MyWorkAsJournalist_fm_mwaj_text_btn__unA9h p {
  font-family: 'Roboto Mono', monospace;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  margin-bottom: 10px;
}
.MyWorkAsJournalist_fm_mwaj_btn__165eR {
  display: flex;
  align-items: center;
}
.MyWorkAsJournalist_fm_mwaj_btn__165eR p {
 font-size: 30px;
 color: #333333;
 font-family: 'Covered By Your Grace', cursive;
 margin-bottom: 0;
}
.MyWorkAsJournalist_fm_mwaj_btn__165eR img {
  display: block;
  margin: 0 28px;
}
.MyWorkAsJournalist_fm_mwaj_btn__165eR a {
  padding: 12px 16px;
  background-color: #333333;
  color: #fff;
  font-family: 'Roboto Mono', monospace;
  font-weight: 400;
  font-size: 15px;
  border-radius: 17px;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 6px;
  white-space: nowrap;
  transition: all .3s ease;
}

.MyWorkAsJournalist_fm_mwaj_logo_holder__jZj2X {
  flex: 1 1;
  display: flex;
  justify-content: center;
}
.MyWorkAsJournalist_fm_mwaj_logo_holder__jZj2X a{
  max-width: 415px;
  width: 100%;
}
.MyWorkAsJournalist_fm_mwaj_logo_holder__jZj2X img {
  display: block;
  max-width: 415px;
  width: 100%;
}

/* HOVER */
@media (hover:hover) {
  .MyWorkAsJournalist_fm_mwaj_btn__165eR a:hover{
    opacity: 0.8;
  }
}

/* MEDIA */
@media only screen and (max-width: 1140px) {
  .MyWorkAsJournalist_fm_mwaj_content__1t-2B{
    padding-left: 60px;
  }
  .MyWorkAsJournalist_fm_mwaj_logo_holder__jZj2X a{
    max-width: 80%;
  }
  .MyWorkAsJournalist_fm_mwaj_logo_holder__jZj2X img {
    max-width: 80%;
  }
}
@media only screen and (max-width: 1024px) {
  .MyWorkAsJournalist_fm_mwaj_content__1t-2B{
    padding-left: 0;
  }
  .MyWorkAsJournalist_fm_mwaj_logo_holder__jZj2X a{
    max-width: 94%;
  }
  .MyWorkAsJournalist_fm_mwaj_logo_holder__jZj2X img {
    max-width: 94%;
  }
}

@media only screen and (max-width: 939px) {
  .MyWorkAsJournalist_fm_mwaj_title__2WwWF{
    font-size: 41px;
  }
  .MyWorkAsJournalist_fm_mwaj_title_r__2SwXU {
    font-size: 41px;
  }
}

@media only screen and (max-width: 767px) {
  .MyWorkAsJournalist_fm_mwaj_text_btn__unA9h {
    max-width: 100%;
    margin-right: 0;
  }
  .MyWorkAsJournalist_fm_mwaj_logo_holder__jZj2X {
    display: none;
  }
  .MyWorkAsJournalist_fm_mwaj_btn__165eR {
    display: none;
  }
}

@media only screen and (max-width: 660px) {
  .MyWorkAsJournalist_fm_mwaj_title__2WwWF{
    flex-direction: column;
    margin-top: -80px;
  }
  .MyWorkAsJournalist_fm_mwaj_title_r__2SwXU {
    font-size: 18px;
    line-height: 18px;
  }
}
.MyWork_fm_container_mywork__3I3tN {
  max-width: 1700px;
  width: 100%;
}
.Press_fm_press_content__21hAc {
  width: 100%;
  padding: 64px 0 128px;
}

.Press_fm_press_articles__1qXpd {
  display: flex;
  justify-content: space-between;
  grid-gap: 46px;
  gap: 46px;
  margin-bottom: 120px;
}

/* single item */
.Press_fm_press_single_item_holder__38Iob {
  max-width: 48%;
  width: 100%;
}
.Press_fm_single_item__C0Q2B {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
}
.Press_fm_single_item__C0Q2B img{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.Press_fm_singl_dark_layer__182E3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 285px;
  height: 100%;
  background-color: #33333357;
  -webkit-filter: brightness(-4);
          filter: brightness(-4);
}
.Press_fm_press_single_infos__I4fyx {
  padding: 50px 6%;
  color: #fff;
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
}
.Press_fm_press_single_state__1L7-9,
.Press_fm_press_single_read_time__2QWcV {
  font-size: 20px;
  color: #FFD51C;
}
.Press_fm_press_single_title__1D19u,
.Press_fm_press_single_title__1D19u a {
  font-size: 56px;
  margin-bottom: 16px;
  max-width: 696px;
  color: inherit;
  text-decoration: none;
  transition: all .3s ease;
}
.Press_fm_press_single_date__2Swam {
  font-size: 20px;
}

/* multi items */
.Press_fm_press_items__2g5Jy {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.Press_fm_press_item__3BT0m {
  display: flex;
  grid-gap: 32px;
  gap: 32px;
  width: 100%;
  min-height: 256px;
  margin-bottom: 30px;
}
.Press_fm_press_item__3BT0m:last-of-type {
  margin-bottom: 0;
}
.Press_fm_press_item_image_holder__3ZA8V {
  max-width: 392px;
  width: 100%;
}
.Press_fm_press_item_image__3l-6q {
  width: 100%;
  height: 100%;
}
.Press_fm_press_item_image__3l-6q img{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.Press_fm_press_item_infos__3Da4A {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
}
.Press_fm_press_item_state__1uuNn {
  font-size: 20px;
  color: #FFD51C;
}
.Press_fm_press_item_title__VIYPb,
.Press_fm_press_item_title__VIYPb a {
  font-size: 30px;
  color: inherit;
  text-decoration: none;
  transition: all .3s ease;
}
.Press_fm_press_item_date__Yls01 {
  font-size: 20px;
  font-weight: 300;
}
.Press_fm_press_item_read_time__3195y{
  font-size: 20px;
  color: #FFD51C;
  font-weight: 300;
}

.Press_fm_see_all_articles__1eKIq {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Press_fm_see_all_articles__1eKIq h2 {
  font-family: 'Covered By Your Grace', cursive;
  font-size: 49px;
  color: #fff;
  margin-bottom: 12px;
}
.Press_fm_see_all_articles__1eKIq img{
  margin-bottom: 38px;
}
.Press_fm_see_all_articles__1eKIq a {
  padding: 21px 28px;
  background-color: #FFD51C;
  color: #333333;
  font-family: 'Roboto Mono', monospace;
  font-weight: 400;
  font-size: 25px;
  border-radius: 17px;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 6px;
  transition: all .3s ease;
}


/* hover */
@media (hover:hover) {
  .Press_fm_press_single_title__1D19u:hover{
    opacity: 0.8;
  }
  .Press_fm_see_all_articles__1eKIq a:hover{
    opacity: 0.8;
  }
}

/* MEDIA */
@media only screen and (max-width: 1140px) {
  .Press_fm_press_articles__1qXpd {
    flex-direction: column;
  }
  .Press_fm_press_single_item_holder__38Iob {
    max-width: 100%;
  }
}

@media only screen and (max-width: 680px) {
  .Press_fm_press_items__2g5Jy {
    grid-gap: 64px;
    gap: 64px;
  }
  .Press_fm_press_item__3BT0m {
    flex-direction: column;
    margin-bottom: 0;
  }
  .Press_fm_press_item_image_holder__3ZA8V {
    max-width: 100%;
  }
  .Press_fm_press_item_image__3l-6q {
    padding-top: 56.25%;
  }

  .Press_fm_see_all_articles__1eKIq h2 {
    font-size: 6vw;
  }
  .Press_fm_see_all_articles__1eKIq img{
    width: 100%;
  }
}
.MyBlog_fm_mb_wrapper__2R3e8 {
  width: 100%;
  background-color: #E6E6E6;
  display: flex;
  justify-content: center;
  padding-bottom: 16px;
}
.MyBlog_fm_mb_content__2CD-m {
  padding-top: 128px;
  padding-left: 125px;
}
.MyBlog_fm_mb_title__21RPG {
  margin-bottom: 16px;
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 60px;
  color: #000000;
}

.MyBlog_fm_mb_text_content__2KVT4 {
  display: flex;
  align-items: flex-start;
}
.MyBlog_fm_mb_text_btn__3bJCJ {
  /* max-width: 828px; */
  max-width: 55%;
  width: 100%;
  margin-right: 24px;
}
.MyBlog_fm_mb_text_btn__3bJCJ p {
  font-family: 'Roboto Mono', monospace;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  margin-bottom: 10px;
}
.MyBlog_fm_mb_btn__1vL8h {
  display: flex;
  align-items: center;
}
.MyBlog_fm_mb_btn__1vL8h p {
 font-size: 30px;
 color: #333333;
 font-family: 'Covered By Your Grace', cursive;
 margin-bottom: 0;
}
.MyBlog_fm_mb_btn__1vL8h .MyBlog_fm_text_underline__lmZC7 img{
  margin: 0;
}
.MyBlog_fm_mb_btn__1vL8h img {
  display: block;
  margin: 0 28px;
}
.MyBlog_fm_mb_btn__1vL8h a {
  padding: 12px 16px;
  background-color: #FFD51C;
  color: #333;
  font-family: 'Roboto Mono', monospace;
  font-weight: 400;
  font-size: 15px;
  border-radius: 17px;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 6px;
  white-space: nowrap;
  transition: all .3s ease;
}

.MyBlog_fm_mb_logo_holder__LGX27 {
  flex: 1 1;
  display: flex;
  justify-content: center;
}
.MyBlog_fm_mb_logo_holder__LGX27 img {
  display: block;
  /* max-width: 415px; */
  max-width: 59%;
  width: 100%;
}



/* HOVER */
@media (hover:hover) {
  .MyBlog_fm_mb_btn__1vL8h a:hover{
    opacity: 0.8;
  }
}


/* MEDIA */
@media only screen and (max-width: 1140px) {
  .MyBlog_fm_mb_content__2CD-m{
    padding-left: 60px;
  }
  .MyBlog_fm_mb_logo_holder__LGX27 img {
    max-width: 80%;
  }
}
@media only screen and (max-width: 1024px) {
  .MyBlog_fm_mb_content__2CD-m{
    padding-left: 0;
  }
  .MyBlog_fm_mb_logo_holder__LGX27 img {
    max-width: 94%;
  }
}

@media only screen and (max-width: 767px) {
  .MyBlog_fm_mb_content__2CD-m{
    padding-top: 64px;
  }
  .MyBlog_fm_mb_text_btn__3bJCJ {
    max-width: 100%;
    margin-right: 0;
  }
  .MyBlog_fm_mb_logo_holder__LGX27 {
    display: none;
  }
  .MyBlog_fm_mb_btn__1vL8h {
    display: none;
  }
}
.MyBlogs_fm_container_blogs__1P6O_ {
  max-width: 1700px;
  width: 100%;
}
.MyBlogs_fm_container_blogs_wrapper__1YJma {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #E6E6E6;
}
.Stories_fm_mb_wrapper__iei0- {
  width: 100%;
  background-color: #E6E6E6;
  display: flex;
  justify-content: center;
  padding-bottom: 16px;
}
.Stories_fm_stories_content__kcDzl {
  width: 100%;
  padding: 64px 0 128px;
  background-color: #E6E6E6;
}

.Stories_fm_stories_articles__6RPSf {
  display: flex;
  justify-content: space-between;
  grid-gap: 46px;
  gap: 46px;
  margin-bottom: 120px;
}

.Stories_fm_stories_items__lqYLJ {
  flex: 1 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.Stories_fm_stories_item__3xi8a {
  display: flex;
  grid-gap: 32px;
  gap: 32px;
  width: 48%;
  min-height: 256px;
  margin-bottom: 30px;
}

.Stories_fm_stories_item_image_holder__2fHLx {
  max-width: 392px;
  width: 100%;
}
.Stories_fm_stories_item_image__2vZI7 {
  width: 100%;
  height: 100%;
}
.Stories_fm_stories_item_image__2vZI7 img{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}

.Stories_fm_stories_item_infos__2eeGw {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  color: #333333;
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
}
.Stories_fm_stories_item_state__6N1nL {
  font-size: 20px;
  color: #FFD51C;
  background-color: #333333;
  padding: 5px 16px;
  border-radius: 13px;
  display: inline-block;
}
.Stories_fm_stories_item_title__2MgcM,
.Stories_fm_stories_item_title__2MgcM a {
  font-size: 30px;
  color: inherit;
  text-decoration: none;
  transition: all .3s ease;
}
.Stories_fm_stories_item_date__3I9M5 {
  font-size: 20px;
  font-weight: 300;
}
.Stories_fm_stories_item_read_time__1oKWv{
  font-size: 20px;
  color: #FFD000;
  font-weight: 300;
}

.Stories_fm_see_all_articles__3TNo6 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Stories_fm_see_all_articles__3TNo6 h2 {
  font-family: 'Covered By Your Grace', cursive;
  font-size: 49px;
  color: #333333;
  margin-bottom: 12px;
}
.Stories_fm_see_all_articles__3TNo6 img{
  margin-bottom: 38px;
}
.Stories_fm_see_all_articles__3TNo6 a {
  padding: 21px 28px;
  background-color: #FFD51C;
  color: #333333;
  font-family: 'Roboto Mono', monospace;
  font-weight: 400;
  font-size: 25px;
  border-radius: 17px;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 6px;
  transition: all .3s ease;
}


/* hover */
@media (hover:hover) {
  .Stories_fm_stories_single_title__2SB5F:hover{
    opacity: 0.8;
  }
  .Stories_fm_see_all_articles__3TNo6 a:hover{
    opacity: 0.8;
  }
}

/* MEDIA */
@media only screen and (max-width: 1366px) {
  .Stories_fm_stories_item__3xi8a {
    flex-direction: column;
  }
  .Stories_fm_stories_item_image_holder__2fHLx {
    max-width: 100%;
  }
  .Stories_fm_stories_item_image__2vZI7 {
    padding-top: 56.25%;
  }
}

@media only screen and (max-width: 680px) {
  .Stories_fm_see_all_articles__3TNo6 h2 {
    font-size: 6vw;
  }
  .Stories_fm_see_all_articles__3TNo6 img{
    width: 100%;
  }
}

@media only screen and (max-width: 640px) {
  .Stories_fm_stories_items__lqYLJ {
    flex-direction: column;
    grid-gap: 64px;
    gap: 64px;
  }
  .Stories_fm_stories_item__3xi8a {
    width: 100%;
    margin: 0;
  }
}
.Footer_fm_footer__2oefC {
  display: flex;
  justify-content: center;
  background-color: #000000;
  padding: 128px 0 75px;
}

.Footer_fm_footer_content__1aIPu {
  display: flex;
  justify-content: space-between;
  grid-gap: 34px;
  gap: 34px;
}

.Footer_fm_footer_left__2R1wg {
  max-width: 32%;
  width: 100%;
}
.Footer_fm_footer_col__Nq31R {
  color: #FBFDFF;
}

.Footer_fm_footer_col__Nq31R .Footer_fm_footer_col_title__E9Gpc {
  margin-bottom: 36px;
  font-family: 'Sora', sans-serif;
  font-weight: 700;
  font-size: 16px;
}
.Footer_fm_footer_col__Nq31R p {
  font-family: 'Sora', sans-serif;
  font-weight: 300;
  font-size: 16px;
  margin-bottom: 40px;
}
.Footer_fm_footer_col__Nq31R p:last-of-type{
  margin-bottom: 0;
}
.Footer_fm_footer_col__Nq31R ul{
  padding: 0;
  margin: 0;
  list-style: none;
  color: #FBFDFF;
}
.Footer_fm_footer_col__Nq31R ul li {
  margin-bottom: 36px;
}
.Footer_fm_footer_col__Nq31R ul li:last-of-type {
  margin-bottom: 0;
}
.Footer_fm_footer_col__Nq31R a {
  font-family: 'Sora', sans-serif;
  font-weight: 300;
  font-size: 16px;
  color: #FBFDFF;
  text-decoration: none;
  transition: all .3s ease;
}

.Footer_fm_footer_right__20zWk {
  flex: 1 1;
  display: flex;
  justify-content: space-around;
}
.Footer_fm_footer_col_socials__2Ht5b {
  margin-bottom: 38px;
}
.Footer_fm_footer_col_socials__2Ht5b a {
  margin-right: 8px;
}
.Footer_fm_footer_col_socials__2Ht5b a:last-of-type{
  margin-right: 0;
}

@media (hover: hover){
  .Footer_fm_footer_col__Nq31R a:hover {
    opacity:  0.8;
  }
}

@media only screen and (max-width: 1100px) {
  .Footer_fm_footer_content__1aIPu {
    flex-direction: column;
    grid-gap: 64px;
    gap: 64px;
  }
  .Footer_fm_footer_left__2R1wg{
    max-width: 100%;
  }
}

@media only screen and (max-width: 640px) {
  .Footer_fm_footer__2oefC{
    padding-top: 64px;
  }
  .Footer_fm_footer_right__20zWk{
    flex-direction: column;
    grid-gap: 32px;
    gap: 32px;
  }
  .Footer_fm_footer_col__Nq31R ul li{
    margin-bottom: 16px;
  }
  .Footer_fm_footer_col__Nq31R .Footer_fm_footer_col_title__E9Gpc{
    margin-bottom: 16px;
  }
  .Footer_fm_footer_col_socials__2Ht5b{
    margin-bottom: 18px;
  }
}

.ArticleImages_fm_article_images__3Tt4N {
  width: 100%;
  padding-top: 100px;
}

.ArticleImages_fm_img_item__2jUED img{
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
  margin: 0 !important;
  display: block;
  padding-right: 20px !important;
}
.ArticleImages_fm_img_item__2jUED:last-of-type img{
  padding-right: 0 !important;
}

.ArticleImages_fm_custom_btn_group__A376k {
  position: absolute;
  top: 0;
  left: 0;
}
.ArticleImages_fm_left_arrow__2_gJM,
.ArticleImages_fm_right_arrow__MtwK9 {
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}
.ArticleImages_fm_left_arrow__2_gJM {
  margin-right: 16px;
}

/* HOVER */
@media (hover:hover){
  .ArticleImages_fm_left_arrow__2_gJM:hover,
  .ArticleImages_fm_right_arrow__MtwK9:hover {
    opacity: 0.8;
  }
}

@media only screen and (max-width: 464px) {
  .ArticleImages_fm_custom_btn_group__A376k {
    right: 0;
    display: flex;
    justify-content: center;
  }
  .ArticleImages_fm_img_item__2jUED img{
    padding-right: 0 !important;
  }
}
.ArticleContent_fm_article_wrapper__1LgTF {
  width: 100%;
  display: flex;
  justify-content: center;
}
.ArticleContent_fm_article_container__2emqV {
  max-width: 1810px;
  width: 100%;
  padding: 0 15px;
}
.ArticleContent_fm_article_content__e0JtA {
  display: flex;
  grid-gap: 70px;
  gap: 70px;
}

/* article left */
.ArticleContent_fm_article_left__3x5Jn {
  width: 43%;
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  margin-left: -15px;
  z-index: 99;
}

.ArticleContent_fm_article_img__Mm21u {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.ArticleContent_fm_article_back_language__3TNFR {
  position: absolute;
  left: 64px;
  right: 64px;
  top: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ArticleContent_fm_back_arrow__2xrGT {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  color: #E6E6E6;
  font-family: 'Roboto Mono', monospace;
  font-weight: 400;
  font-size: 25px;
  cursor: pointer;
  transition: all .3s ease;
}
.ArticleContent_fm_back_language__36gOn {
  background-color: #FFD51C;
  color: #333333;
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 16px;
  padding: 5px 28px;
  border-radius: 16px;
}

.ArticleContent_fm_article_infos__UBYA_ {
  position: absolute;
  bottom: 32px;
  left: 0;
  width: 100%;
  background-color: #33333357;
  -webkit-filter: brightness(-4);
          filter: brightness(-4);
  padding: 7px 27px 7px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ArticleContent_fm_article_info__2Yb9_{
  font-family: 'Covered By Your Grace', cursive;
  font-size: 30px;
  color: #FFFFFF;
  margin-right: 24px;
}
.ArticleContent_fm_article_state__pUd4n span {
  color: #FFD51C;
}

.ArticleContent_fm_article_share__1bqsn {
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 16px;
  color: #FFFFFF;
  text-align: right;
}
.ArticleContent_fm_article_share_title__5d-bz {
  margin-bottom: 16px;
}
.ArticleContent_fm_article_share_icons__2mT4Y {
  display: flex;
  grid-gap: 12px;
  gap: 12px;
  justify-content: flex-end;
}

/* article right */
.ArticleContent_fm_article_right__2WhQq {
  flex: 1 1;
  padding-top: 60px;
  padding-bottom: 20px;
  width: calc(100% - (43% + 70px));
  padding-left: calc(43% + 70px);
}
.ArticleContent_fm_article_right_state___SRBE{
  color: #FFD51C;
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 21px;
}
.ArticleContent_fm_article_right_title__2iyIY h1 {
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 56px;
  color: #333333;
  max-width: 693px;
  margin-bottom: 46px;
}
.ArticleContent_fm_article_right_date_read__3So6G{
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 46px;
  color: #333333;
}
.ArticleContent_fm_article_right_author__19_JI{
  font-family: 'Roboto Mono', monospace;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  margin-bottom: 20px;
}
.ArticleContent_fm_article_right_content__1l8V2 {
  -webkit-columns: 2;
          columns: 2;
  grid-column-gap: 55px;
  -webkit-column-gap: 55px;
          column-gap: 55px;
  margin-bottom: 43px;
}
.ArticleContent_fm_article_right_content__1l8V2 p{
  font-family: 'Roboto Mono', monospace;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  margin-bottom: 20px;
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
          break-inside: avoid;
}

/* CAROUSEL */
.ArticleContent_fm_carousel_holder__2Ymij {
  width: 100%;
  padding-top: 40px;
}

/* hover */
@media (hover:hover) {
  .ArticleContent_fm_back_arrow__2xrGT:hover {
    opacity: 0.8;
  }
  .ArticleContent_fm_article_share_icons__2mT4Y img:hover{
    opacity: 0.8;
  }
}

/* MEDIA */
@media only screen and (max-width: 1920px) {
  .ArticleContent_fm_article_wrapper__1LgTF {
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 1730px) {
  .ArticleContent_fm_article_wrapper__1LgTF {
    justify-content: center;
  }
  .ArticleContent_fm_article_container__2emqV {
    max-width: 1730px;
  }
}

@media only screen and (max-width: 1366px) {
  .ArticleContent_fm_article_content__e0JtA {
    grid-gap: 34px;
    gap: 34px;
  }
  .ArticleContent_fm_article_right_content__1l8V2 {
    -webkit-columns: 1;
            columns: 1;
  }
}
@media only screen and (max-width: 1140px) {
  .ArticleContent_fm_article_content__e0JtA {
    grid-gap: 64px;
    gap: 64px;
    flex-direction: column;
  }
  /* article left */
  .ArticleContent_fm_article_left__3x5Jn {
    width: calc(100% + 30px);
  }
  .ArticleContent_fm_article_back_language__3TNFR{
    left: 15px;
  }
  .ArticleContent_fm_back_arrow__2xrGT span,
  .ArticleContent_fm_back_language__36gOn{
    display: none;
  }
  .ArticleContent_fm_back_arrow__2xrGT img {
    width: 34px;
  }
  .ArticleContent_fm_article_infos__UBYA_{
    padding-right: 15px;
    padding-left: 15px;
  }
  .ArticleContent_fm_article_info__2Yb9_{
    font-size: 16px; 
  }
  .ArticleContent_fm_article_share__1bqsn{
    font-size: 10px;
    text-align: center;
  }
  .ArticleContent_fm_article_share_icons__2mT4Y{
    justify-content: center;
  }
  .ArticleContent_fm_article_share_title__5d-bz{
    margin-bottom: 6px;
  }
  .ArticleContent_fm_article_share_icons__2mT4Y a img{
    width: 15px;
  }

  /* article right */
  .ArticleContent_fm_article_right__2WhQq{
    width: 100%;
    padding-top: 0;
  }
  .ArticleContent_fm_article_right_state___SRBE {
    margin-bottom: 0;
  }
  .ArticleContent_fm_article_right_title__2iyIY h1 {
    line-height: 55px;
  }
}
.TopBanner_fm_tnb_wrapper__2zyRA {
  margin-top: 64px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.TopBanner_fm_tnb_content__gbjcH {
  width: 100%;
  min-height: 775px;
  display: flex;
}
.TopBanner_fm_tnb_content__gbjcH img{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: center;
}
.TopBanner_fm_img_dark_layer__ihYp0{
  position: relative;
  top: 0;
  left: 0;
  max-width: 608px;
  width: 100%;
  /* height: 100%; */
  background-color: #33333357;
  padding: 50px 20px;
  display: flex;
  align-items: flex-end;
}
.TopBanner_fm_article_infos__QkNKe {
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  color: #FFFFFF;
}
.TopBanner_fm_article_right_state__19GLP{
  color: #FFD51C;
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 20px;
}
.TopBanner_fm_article_right_title__8tLb7 a{
  display: block;
  text-decoration: none;
}
.TopBanner_fm_article_right_title__8tLb7 h1 {
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 56px;
  color: #FFFFFF;
  max-width: 693px;
  margin-bottom: 16px;
  transition: all .3s ease;
}
.TopBanner_fm_article_right_date_read__3xKCc{
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 20px;
  color: #FFFFFF;
}
.TopBanner_fm_article_right_read__vh6sS {
  color: #FFD51C;
}


/* hover */
@media (hover:hover) {
  .TopBanner_fm_article_right_title__8tLb7 h1:hover{
    opacity: 0.8;
  }
}

/* MEDIA */
@media only screen and (max-width: 1140px) {
  .TopBanner_fm_tnb_content__gbjcH {
    width: calc(100% + 30px);
    margin-left: -15px;
  }
}
@media only screen and (max-width: 1024px) {}

@media only screen and (max-width: 767px) {}


.SelectCountry_fm_select_holder__3cBiA{
    max-width: 258px;
    width: 100%;
}

/* HOVER */
@media (hover: hover) {
}

/*  MEDIA */
@media only screen and (max-width: 940px) {
}

@media only screen and (max-width: 700px) {
 
}
.SelectYear_fm_select_holder__1Lypx{
    max-width: 258px;
    width: 100%;
}

/* HOVER */
@media (hover: hover) {
}

/*  MEDIA */
@media only screen and (max-width: 940px) {
}

@media only screen and (max-width: 700px) {
 
}
.SelectLanguage_fm_select_holder__2B39z{
    max-width: 258px;
    width: 100%;
}

/* HOVER */
@media (hover: hover) {
}

/*  MEDIA */
@media only screen and (max-width: 940px) {
}

@media only screen and (max-width: 700px) {
 
}
.FilterInput_fm_input_holder__23H2E{
    max-width: 576px;
    width: 100%;
}

.FilterInput_fm_input_holder__23H2E input {
    max-width: 100%;
    width: 100%;
    border: 4px solid #FFD51C;
    border-radius: 15px;
    background: #333333;
    height: 61px;
    outline: none;
    color: #E6E6E6;
    font-size: 16px;
    font-family: 'Palanquin';
    font-weight: 400;
    padding: 12px 70px 12px 16px;
}

.FilterInput_fm_input_holder__23H2E img{
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    right: 20px;
    cursor: pointer;
}


/* HOVER */
@media (hover: hover) {
    .FilterInput_fm_input_holder__23H2E img:hover {
        opacity: 0.8;
    }
}

/*  MEDIA */
@media only screen and (max-width: 1024px) {
    .FilterInput_fm_input_holder__23H2E{
        max-width: 100%;
    }
}

.Filters_fm_filters_wrapper__CKWQC {
  padding: 34px 0;
  width: 100%;
  display: flex;
  justify-content: center;
}
.Filters_fm_filters_content__2ZNJV {
  width: 100%;
}
.Filters_fm_open_filters_small__2-5g5 {
  width: 100%;
  background-color: #FFD51C;
  color: #333;
  padding: 14px;
  text-align: center;
  font-family: 'Roboto Mono', monospace;
  font-weight: 400;
  font-size: 16px;
  border-radius: 9px;
  cursor: pointer;
  margin-bottom: 64px;
  transition: all .3s ease;
}
.Filters_fm_open_filters_small__2-5g5 {
  display: none;
}
.Filters_fm_filters_holder__2cFQ2{
  height: auto;
  overflow: visible;
  transition: all .3s ease;
}
.Filters_fm_filters_holder__2cFQ2.Filters_fm_filters_open__3BHgW {
  height: auto;
}
.Filters_fm_filters_top__3OP60 {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.Filters_fm_filters_left__sF03V {
  flex: 1 1;
  display: flex;
  grid-gap: 64px;
  gap: 64px;
  margin-right: 64px;
}

.Filters_fm_filters_right__3ox_P {
  /* max-width: 576px; */
  max-width: 34%;
  width: 100%;
}


/* filters bottom */
.Filters_fm_filters_bot__1JTox {
  margin-top: 16px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 32px;
  gap: 32px;
}

.Filters_fm_filtrers_selected_item__2Llrv {
  max-width: 167px;
  width: 100%;
  padding: 5px 30px 5px 16px;
  color: #333333;
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 16px;
  background-color: #FFD51C;
  border-radius: 16px;
}

.Filters_fm_filtrers_selected_item__2Llrv img {
  position: absolute;
  right: 6px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  cursor: pointer;
}

/* hover */
@media (hover:hover) {
  .Filters_fm_open_filters_small__2-5g5:hover {
    opacity: 0.8;
  }
  .Filters_fm_filtrers_selected_item__2Llrv img:hover{ 
    opacity: 0.8;
  }
}


/* MEDIA */
@media only screen and (max-width: 1240px) {
  .Filters_fm_filters_left__sF03V{
    grid-gap: 30px;
    gap: 30px;
    margin-right: 30px;
  }
}

@media only screen and (max-width: 1024px) {
  .Filters_fm_filters_left__sF03V{
    grid-gap: 14px;
    gap: 14px;
    margin-right: 14px;
  }
}

@media only screen and (max-width: 1024px) {
  .Filters_fm_filters_top__3OP60{
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
  }
  .Filters_fm_filters_left__sF03V{
    margin-right: 0;
  }
  .Filters_fm_filters_right__3ox_P {
    max-width: 100%;
  }
}

@media only screen and (max-width: 680px) {
  .Filters_fm_open_filters_small__2-5g5 {
    display: block;
  }
  .Filters_fm_filters_holder__2cFQ2{
    height: 0;
  }
  .Filters_fm_filtrers_selected_item__2Llrv{
    max-width: 48%;
    margin-bottom: 2%;
  }
  .Filters_fm_filters_left__sF03V{
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
    margin-right: 0;
  }
  .Filters_fm_filters_bot__1JTox {
    grid-gap: initial;
    gap: initial;
    justify-content: space-between;
  }
}
.PressArticle_fm_article__3RzBh {
  max-width: calc(50% - 24px);
  width: 100%;
  margin-bottom: 64px;
  border: 4px solid transparent;
  border-radius: 10px;
}
.PressArticle_fm_article__3RzBh:hover {
  border: 4px solid #ffd51b;
}
.PressArticle_fm_article_img___VGrm {
  width: 100%;
  padding-top: 56.25%;
  margin-bottom: 64px;
}
.PressArticle_fm_article_img___VGrm img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.PressArticle_fm_article_state__24Ulq{
  color: #FFD51C;
  font-weight: 500;
  font-family: 'Roboto Mono', monospace;
  font-size: 20px;
  margin-bottom: 16px;
}
.PressArticle_fm_article_title__1T7oI a {
  color: #fff;
  font-weight: 500;
  font-family: 'Roboto Mono', monospace;
  font-size: 30px;
  text-decoration: none;
  transition: all .3s ease;
}
.PressArticle_fm_article_short_descritpiton__3FKDk{
  color: #fff;
  font-weight: 300;
  font-family: 'Roboto Mono', monospace;
  font-size: 20px;
  margin-bottom: 8px;
  max-height: 128px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.PressArticle_fm_article_date__1sfSH{
  color: #fff;
  font-weight: 300;
  font-family: 'Roboto Mono', monospace;
  font-size: 20px;
}
.PressArticle_fm_article_read_time__2jOIS{
  color: #FFD51C;
  font-weight: 300;
  font-family: 'Roboto Mono', monospace;
  font-size: 20px;
}


/* hover */
@media (hover:hover) {
  .PressArticle_fm_article_title__1T7oI a:hover{
    opacity: 0.8;
  }
}

/* MEDIA */
@media only screen and (max-width: 1366px) {
  .PressArticle_fm_article_img___VGrm {
    margin-bottom: 32px;
  }
  .PressArticle_fm_article_state__24Ulq{
    margin-bottom: 10px;
  }
  .PressArticle_fm_article_title__1T7oI{
    margin-bottom: 16px;
  }
  .PressArticle_fm_article_short_descritpiton__3FKDk{
    font-size: 16px;
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 640px) {
  .PressArticle_fm_article__3RzBh {
    max-width: 100%;
    margin-bottom: 0;
  }
}

.Articles_fm_articles_holder__35MUH {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 60px;
}



/* hover */
@media (hover:hover) {
 
}

/* MEDIA */
@media only screen and (max-width: 640px) {
  .Articles_fm_articles_holder__35MUH{
    flex-direction: column;
    grid-gap: 64px;
    gap: 64px;
  }
}
.BlogArticle_fm_article__10we5 {
  max-width: calc(50% - 24px);
  width: 100%;
  margin-bottom: 32px;
  display: flex;
  border: 4px solid transparent;
  border-radius: 10px;
}
.BlogArticle_fm_article__10we5:hover {
  border: 4px solid #ffd51b;
}
.BlogArticle_fm_article_img__2UqqV {
  max-width: 392px;
  width: 100%;
  margin-right: 32px;
}
.BlogArticle_fm_article_img__2UqqV img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}
.BlogArticle_fm_article_infos__1sStK{
  flex: 1 1;
}
.BlogArticle_fm_article_state__eIFGQ{
  color: #FFD51C;
  font-weight: 700;
  font-family: 'Roboto Mono', monospace;
  font-size: 20px;
  margin-bottom: 16px;
  padding: 5px 16px;
  background-color: #333333;
  border-radius: 13px;
  display: inline-block;
}
.BlogArticle_fm_article_title__3biw8 {
  margin-bottom: 22px;
}
.BlogArticle_fm_article_title__3biw8 a {
  color: #333333;
  font-weight: 500;
  font-family: 'Roboto Mono', monospace;
  font-size: 30px;
  text-decoration: none;
  transition: all .3s ease;
}
.BlogArticle_fm_article_date__NhdGv{
  color: #333333;
  font-weight: 300;
  font-family: 'Roboto Mono', monospace;
  font-size: 20px;
}
.BlogArticle_fm_article_read_time__1BKLi{
  color: #FFD000;
  font-weight: 300;
  font-family: 'Roboto Mono', monospace;
  font-size: 20px;
  margin-bottom: 16px;
}

.BlogArticle_fm_article_text__QjbBA {
  max-height: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
}


/* hover */
@media (hover:hover) {
  .BlogArticle_fm_article_title__3biw8 a:hover{
    opacity: 0.8;
  }
}

/* MEDIA */
@media only screen and (max-width: 1366px) {
  .BlogArticle_fm_article__10we5 {
    flex-direction: column;
  }
  .BlogArticle_fm_article_img__2UqqV {
    max-width: 100%;
    margin-right: 0;
    padding-top: 56.25%;
    margin-bottom: 34px;
  }
  .BlogArticle_fm_article_state__eIFGQ{
    margin-bottom: 10px;
  }
  .BlogArticle_fm_article_title__3biw8{
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 640px) {
  .BlogArticle_fm_article__10we5 {
    max-width: 100%;
  }
}

.Blogs_fm_blogs_holder__3shds {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 60px;
}



/* hover */
@media (hover:hover) {
 
}

/* MEDIA */
@media only screen and (max-width: 640px) {
  .Blogs_fm_blogs_holder__3shds{
    flex-direction: column;
    grid-gap: 64px;
    gap: 64px;
  }
}
.Navigation_fm_nav_wrap__1FbpY {
  display: flex;
  justify-content: center;
}
.Navigation_fm_nav_holder__2ahR6{
  position: absolute;
  top: 32px;
  right: 15px;
}
.Navigation_fm_nav_open__3CLRW img {
  width: 60px;
  display: block;
  cursor: pointer;
  z-index: 9;
}

.Navigation_fm_nav__3hRl5 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  padding: 32px 0 15px;
  background-color: #FFD51C;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all .3s ease;
}
.Navigation_fm_nav__3hRl5.Navigation_fm_nav_open__3CLRW{
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}


.Navigation_fm_nav_content__1tyYK {
  max-width: 1730px;
  width: 100%;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.Navigation_fm_nav_close__Jodzr{
  position: absolute;
  top: -10px;
  right: 15px;
}
.Navigation_fm_nav_menu_text__1FDVw {
  font-family: 'Roboto Mono', monospace;
  font-weight: bold;
  font-size: 70px;
  color: #333333;
  margin-bottom: 72px;
}
.Navigation_fm_nav_items__AnLVP{
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.Navigation_fm_nav__3hRl5 ul {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;
}
.Navigation_fm_nav__3hRl5 ul li:last-of-type{
  margin-bottom: 44px;
}
.Navigation_fm_nav__3hRl5 ul li:first-of-type a{
  margin-left: 120px;
}
.Navigation_fm_nav__3hRl5 ul li:last-of-type a{
  margin-left: 200px;
}
.Navigation_fm_nav__3hRl5 ul li a{
  text-decoration: none; 
  color: #33333366;
  font-family: 'Roboto Mono', monospace;
  font-weight: bold;
  font-size: 55px;
  position: relative;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
}
.Navigation_fm_nav__3hRl5 ul li a span {
  font-size: 70px;
  -webkit-text-decoration-line: line-through;
          text-decoration-line: line-through;
}
.Navigation_fm_nav__3hRl5 ul li a.Navigation_active__fPOcU {
  color: #333333;
}
.Navigation_fm_nav__3hRl5 ul li a.Navigation_active__fPOcU span {
  color: #333333;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
}

.Navigation_fm_contact_info__3JYmc{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.Navigation_fm_contact__2K_gb {
  margin-right: 24px;
}
.Navigation_fm_contact_text__RUry2{
  color: #333333;
  font-family: 'Roboto Mono', monospace;
  font-weight: 300;
  font-size: 55px;
}
.Navigation_fm_contact_mail__26ROJ a{
  color: #333333;
  font-family: 'Roboto Mono', monospace;
  font-weight: 300;
  font-size: 30px;
  text-decoration: none;
}

.Navigation_fm_social_networks__HH8KP {
  display: flex;
  justify-content: flex-end;
}
.Navigation_fm_social_networks_support_msg__vy0la {
  position: absolute;
  text-align: center;
  bottom: 125px;
  right: -76px;
  color: #333333;
  font-family: 'Covered By Your Grace', cursive;
  font-size: 60px;
  width: 560px;
  opacity: 0.5;
  line-height: 80px;
  text-transform: uppercase;
  -webkit-transform: rotate(-26deg);
          transform: rotate(-26deg);
  pointer-events: none;
}

.Navigation_fm_social_networks_logo_socials__cWlXt {
  display: flex;
  align-items: flex-end;
}
.Navigation_fm_social_networks_logo__ESxtG{
  margin-right: 35px;
}
.Navigation_fm_social_networks_logo__ESxtG img {
  width: 170px;
  display: block;
}
.Navigation_fm_socials_text__3-dbp{
  color: #333333;
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 16px;
  text-align: center;
}
.Navigation_fm_socials__2ABsW {
  display: flex;
}
.Navigation_fm_socials__2ABsW a {
  display: inline-block;
  margin-right: 12px;
}
.Navigation_fm_socials__2ABsW a:last-of-type {
  margin-right: 0;
}
.Navigation_fm_socials__2ABsW a img{
  width: 29px;
  display: block;
}

/* hover */
@media (hover:hover){
  .Navigation_fm_nav__3hRl5 ul li a:hover,
  .Navigation_fm_nav__3hRl5 ul li a:hover span{
    color: #333333;
    -webkit-text-decoration-line: none;
            text-decoration-line: none;
  }
}

/* MEDIA */
@media only screen and (max-width: 1667px) {
  .Navigation_fm_nav_menu_text__1FDVw{
    margin-bottom: 34px;
  }
  .Navigation_fm_nav_menu_text__1FDVw{
    font-size: 4vw;
  }
  .Navigation_fm_nav_items__AnLVP ul li:first-of-type a{
    font-size: 3.5vw;
  }
  .Navigation_fm_nav_items__AnLVP ul li:first-of-type a span{
    font-size: 4vw;
  }
  .Navigation_fm_nav_items__AnLVP ul li:last-of-type a span{
    font-size: 4vw;
  }
  .Navigation_fm_contact_text__RUry2{
    font-size: 3.5vw;
  }
  .Navigation_fm_contact_mail__26ROJ a {
    font-size: 1.4vw;
  }
  .Navigation_fm_social_networks_support_msg__vy0la {
    font-size: 2.8vw;
    line-height: normal;
  }
}
@media only screen and (max-width: 1140px) {
  .Navigation_fm_nav_holder__2ahR6{
    top: 16px;
  }
  .Navigation_fm_nav__3hRl5 {
    padding: 24px 0 15px;
  } 
}
@media only screen and (max-width: 767px) {
  .Navigation_fm_social_networks_support_msg__vy0la{
    display: none;
  }
  .Navigation_fm_social_networks_logo__ESxtG {
    display: none;
  }
  .Navigation_fm_contact_text__RUry2{
    display: none;
  } 
}
@media only screen and (max-width: 464px) {
  .Navigation_fm_nav_menu_text__1FDVw{
    line-height: 40px;
    margin-bottom: 80px;
    font-size: 30vw;
  }
  .Navigation_fm_nav_items__AnLVP ul li:first-of-type{
    text-align: right;
  }
  .Navigation_fm_nav_items__AnLVP ul li:first-of-type a{
    font-size: 5vw;
  }
  .Navigation_fm_nav_items__AnLVP ul li:first-of-type a span{
    font-size: 14.6vw;
  }
  .Navigation_fm_nav_items__AnLVP ul li:last-of-type a{
    margin-left: 0;
  }
  .Navigation_fm_nav_items__AnLVP ul li:last-of-type a span{
    font-size: 30vw;
  }
  .Navigation_fm_contact_info__3JYmc{
    flex-direction: column-reverse;
    align-items: center;
  }
  .Navigation_fm_contact__2K_gb {
    margin-right: 0;
  }
  .Navigation_fm_contact_mail__26ROJ a{
    font-size: 16px;
  }
  .Navigation_fm_socials_holder__1C3D_ {
    margin-bottom: 30px;
  }
}

.LoginForm_formWrapper__2chep {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    box-shadow: 0 0 20px 5px #eee;
    padding: 16px;
    text-align: center;
    border-radius: 8px;
}
