.fm_input_holder{
    max-width: 576px;
    width: 100%;
}

.fm_input_holder input {
    max-width: 100%;
    width: 100%;
    border: 4px solid #FFD51C;
    border-radius: 15px;
    background: #333333;
    height: 61px;
    outline: none;
    color: #E6E6E6;
    font-size: 16px;
    font-family: 'Palanquin';
    font-weight: 400;
    padding: 12px 70px 12px 16px;
}

.fm_input_holder img{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    cursor: pointer;
}


/* HOVER */
@media (hover: hover) {
    .fm_input_holder img:hover {
        opacity: 0.8;
    }
}

/*  MEDIA */
@media only screen and (max-width: 1024px) {
    .fm_input_holder{
        max-width: 100%;
    }
}
