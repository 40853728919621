.fm_mb_wrapper {
  width: 100%;
  background-color: #E6E6E6;
  display: flex;
  justify-content: center;
  padding-bottom: 16px;
}
.fm_mb_content {
  padding-top: 128px;
  padding-left: 125px;
}
.fm_mb_title {
  margin-bottom: 16px;
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 60px;
  color: #000000;
}

.fm_mb_text_content {
  display: flex;
  align-items: flex-start;
}
.fm_mb_text_btn {
  /* max-width: 828px; */
  max-width: 55%;
  width: 100%;
  margin-right: 24px;
}
.fm_mb_text_btn p {
  font-family: 'Roboto Mono', monospace;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  margin-bottom: 10px;
}
.fm_mb_btn {
  display: flex;
  align-items: center;
}
.fm_mb_btn p {
 font-size: 30px;
 color: #333333;
 font-family: 'Covered By Your Grace', cursive;
 margin-bottom: 0;
}
.fm_mb_btn .fm_text_underline img{
  margin: 0;
}
.fm_mb_btn img {
  display: block;
  margin: 0 28px;
}
.fm_mb_btn a {
  padding: 12px 16px;
  background-color: #FFD51C;
  color: #333;
  font-family: 'Roboto Mono', monospace;
  font-weight: 400;
  font-size: 15px;
  border-radius: 17px;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 6px;
  white-space: nowrap;
  transition: all .3s ease;
}

.fm_mb_logo_holder {
  flex: 1;
  display: flex;
  justify-content: center;
}
.fm_mb_logo_holder img {
  display: block;
  /* max-width: 415px; */
  max-width: 59%;
  width: 100%;
}



/* HOVER */
@media (hover:hover) {
  .fm_mb_btn a:hover{
    opacity: 0.8;
  }
}


/* MEDIA */
@media only screen and (max-width: 1140px) {
  .fm_mb_content{
    padding-left: 60px;
  }
  .fm_mb_logo_holder img {
    max-width: 80%;
  }
}
@media only screen and (max-width: 1024px) {
  .fm_mb_content{
    padding-left: 0;
  }
  .fm_mb_logo_holder img {
    max-width: 94%;
  }
}

@media only screen and (max-width: 767px) {
  .fm_mb_content{
    padding-top: 64px;
  }
  .fm_mb_text_btn {
    max-width: 100%;
    margin-right: 0;
  }
  .fm_mb_logo_holder {
    display: none;
  }
  .fm_mb_btn {
    display: none;
  }
}