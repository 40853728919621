.fm_nav_wrap {
  display: flex;
  justify-content: center;
}
.fm_nav_holder{
  position: absolute;
  top: 32px;
  right: 15px;
}
.fm_nav_open img {
  width: 60px;
  display: block;
  cursor: pointer;
  z-index: 9;
}

.fm_nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  padding: 32px 0 15px;
  background-color: #FFD51C;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all .3s ease;
}
.fm_nav.fm_nav_open{
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}


.fm_nav_content {
  max-width: 1730px;
  width: 100%;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.fm_nav_close{
  position: absolute;
  top: -10px;
  right: 15px;
}
.fm_nav_menu_text {
  font-family: 'Roboto Mono', monospace;
  font-weight: bold;
  font-size: 70px;
  color: #333333;
  margin-bottom: 72px;
}
.fm_nav_items{
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.fm_nav ul {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;
}
.fm_nav ul li:last-of-type{
  margin-bottom: 44px;
}
.fm_nav ul li:first-of-type a{
  margin-left: 120px;
}
.fm_nav ul li:last-of-type a{
  margin-left: 200px;
}
.fm_nav ul li a{
  text-decoration: none; 
  color: #33333366;
  font-family: 'Roboto Mono', monospace;
  font-weight: bold;
  font-size: 55px;
  position: relative;
  text-decoration-line: none;
}
.fm_nav ul li a span {
  font-size: 70px;
  text-decoration-line: line-through;
}
.fm_nav ul li a.active {
  color: #333333;
}
.fm_nav ul li a.active span {
  color: #333333;
  text-decoration-line: none;
}

.fm_contact_info{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.fm_contact {
  margin-right: 24px;
}
.fm_contact_text{
  color: #333333;
  font-family: 'Roboto Mono', monospace;
  font-weight: 300;
  font-size: 55px;
}
.fm_contact_mail a{
  color: #333333;
  font-family: 'Roboto Mono', monospace;
  font-weight: 300;
  font-size: 30px;
  text-decoration: none;
}

.fm_social_networks {
  display: flex;
  justify-content: flex-end;
}
.fm_social_networks_support_msg {
  position: absolute;
  text-align: center;
  bottom: 125px;
  right: -76px;
  color: #333333;
  font-family: 'Covered By Your Grace', cursive;
  font-size: 60px;
  width: 560px;
  opacity: 0.5;
  line-height: 80px;
  text-transform: uppercase;
  transform: rotate(-26deg);
  pointer-events: none;
}

.fm_social_networks_logo_socials {
  display: flex;
  align-items: flex-end;
}
.fm_social_networks_logo{
  margin-right: 35px;
}
.fm_social_networks_logo img {
  width: 170px;
  display: block;
}
.fm_socials_text{
  color: #333333;
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 16px;
  text-align: center;
}
.fm_socials {
  display: flex;
}
.fm_socials a {
  display: inline-block;
  margin-right: 12px;
}
.fm_socials a:last-of-type {
  margin-right: 0;
}
.fm_socials a img{
  width: 29px;
  display: block;
}

/* hover */
@media (hover:hover){
  .fm_nav ul li a:hover,
  .fm_nav ul li a:hover span{
    color: #333333;
    text-decoration-line: none;
  }
}

/* MEDIA */
@media only screen and (max-width: 1667px) {
  .fm_nav_menu_text{
    margin-bottom: 34px;
  }
  .fm_nav_menu_text{
    font-size: 4vw;
  }
  .fm_nav_items ul li:first-of-type a{
    font-size: 3.5vw;
  }
  .fm_nav_items ul li:first-of-type a span{
    font-size: 4vw;
  }
  .fm_nav_items ul li:last-of-type a span{
    font-size: 4vw;
  }
  .fm_contact_text{
    font-size: 3.5vw;
  }
  .fm_contact_mail a {
    font-size: 1.4vw;
  }
  .fm_social_networks_support_msg {
    font-size: 2.8vw;
    line-height: normal;
  }
}
@media only screen and (max-width: 1140px) {
  .fm_nav_holder{
    top: 16px;
  }
  .fm_nav {
    padding: 24px 0 15px;
  } 
}
@media only screen and (max-width: 767px) {
  .fm_social_networks_support_msg{
    display: none;
  }
  .fm_social_networks_logo {
    display: none;
  }
  .fm_contact_text{
    display: none;
  } 
}
@media only screen and (max-width: 464px) {
  .fm_nav_menu_text{
    line-height: 40px;
    margin-bottom: 80px;
    font-size: 30vw;
  }
  .fm_nav_items ul li:first-of-type{
    text-align: right;
  }
  .fm_nav_items ul li:first-of-type a{
    font-size: 5vw;
  }
  .fm_nav_items ul li:first-of-type a span{
    font-size: 14.6vw;
  }
  .fm_nav_items ul li:last-of-type a{
    margin-left: 0;
  }
  .fm_nav_items ul li:last-of-type a span{
    font-size: 30vw;
  }
  .fm_contact_info{
    flex-direction: column-reverse;
    align-items: center;
  }
  .fm_contact {
    margin-right: 0;
  }
  .fm_contact_mail a{
    font-size: 16px;
  }
  .fm_socials_holder {
    margin-bottom: 30px;
  }
}
