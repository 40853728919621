.fm_select_holder{
    max-width: 258px;
    width: 100%;
}

/* HOVER */
@media (hover: hover) {
}

/*  MEDIA */
@media only screen and (max-width: 940px) {
}

@media only screen and (max-width: 700px) {
 
}