.fm_article_images {
  width: 100%;
  padding-top: 100px;
}

.fm_img_item img{
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
  margin: 0 !important;
  display: block;
  padding-right: 20px !important;
}
.fm_img_item:last-of-type img{
  padding-right: 0 !important;
}

.fm_custom_btn_group {
  position: absolute;
  top: 0;
  left: 0;
}
.fm_left_arrow,
.fm_right_arrow {
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}
.fm_left_arrow {
  margin-right: 16px;
}

/* HOVER */
@media (hover:hover){
  .fm_left_arrow:hover,
  .fm_right_arrow:hover {
    opacity: 0.8;
  }
}

@media only screen and (max-width: 464px) {
  .fm_custom_btn_group {
    right: 0;
    display: flex;
    justify-content: center;
  }
  .fm_img_item img{
    padding-right: 0 !important;
  }
}