.fm_heading_content {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  align-items: center;
  padding: 72px 0 14px;
}

/* heading left */
.fm_heading_left {
  max-width: 34%;
  width: 100%;
  margin-right: 24px;
  height: 100%;
  overflow: hidden;
  padding-bottom: 36px;
}
.fm_heading_image_holder {
  position: relative;
  height: 100%;
  padding: 0 30px;
  overflow: hidden;
}
.fm_heading_img_bg {
  position: absolute;
  top: 93px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  object-fit: cover;
  object-position: center;
  height: 100%;
}

.fm_heading_img_holder {
  padding-bottom: 74px;
  width: 100%;
  height: 100%;
}
.fm_heading_img {
  display: block;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  object-fit: cover;
  object-position: top;
}

/* heading right */
.fm_heading_right{
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.fm_name_title_infos_holder {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fm_name_title_infos{
  display: flex;
  align-items: flex-end;
  font-family: 'Palanquin', sans-serif;
  font-weight: 600;
  font-size: 128px;
  color: #FFD51C;
}
.fm_name_title_and {
  margin-right: 11px;
  line-height: 100px;
}
.fm_name_title{
  margin-bottom: 20px;
  line-height: 110px;
}
.fm_name_job {
  font-family: 'Palanquin', sans-serif;
  font-weight: 600;
  font-size: 45px;
  line-height: 45px;
  color: #FFFFFF;
}

/* socials */
.fm_social_networks_logo_socials {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.fm_social_networks_logo{
  margin-right: 35px;
}
.fm_social_networks_logo img {
  width: 170px;
  display: block;
  z-index: 3;
}
.fm_socials_holder {
  z-index: 3;
}
.fm_socials_text{
  color: #FFFFFF;
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 16px;
  text-align: center;
}
.fm_socials {
  display: flex;
}
.fm_socials a {
  display: inline-block;
  margin-right: 12px;
}
.fm_socials a:last-of-type {
  margin-right: 0;
}
.fm_socials a img{
  width: 29px;
  display: block;
}


/* scroll */
.fm_scroll_icon_holder{
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 2;
  color: #FFD51C;
  font-family: 'Sora', sans-serif;
  font-size: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 14px;
}
.fm_scroll_icon_holder img {
  margin-bottom: 8px;
}

/* MEDIA */
@media only screen and (max-width: 1140px) {
  /* heading left */
  .fm_heading_left {
    height: auto;
  }
  .fm_heading_img_holder{
    padding-bottom: 30px;
  }

  /* heading right */
  .fm_name_title_infos{
    font-size: 80px;
  }
  .fm_name_title_and,
  .fm_name_title{
    line-height: 70px;
  }
  .fm_name_job{
    font-size: 28px;
    line-height: 34px;
  }
}

@media only screen and (max-width: 767px) {
  .fm_heading_content {
    flex-direction: column-reverse;
    justify-content: center;
  }
  
  /* heading left */
  .fm_heading_left{
    margin-right: 0;
    max-width: 275px;
    width: 100%;
  }

  /* heading right */
  .fm_heading_right{
    height: auto;
    flex: auto;
    margin-bottom: 34px;
  }
  .fm_name_title_infos{
    font-size: 54px;
  }
  .fm_name_title_and,
  .fm_name_title{
    line-height: 48px;
  }
  .fm_name_job{
    font-size: 19px;
    line-height: 24px;
  }
  .fm_social_networks_logo_socials{
    display: none;
  }
  .fm_scroll_icon_holder{
    display: none;
  }
}