.fm_mb_wrapper {
  width: 100%;
  background-color: #E6E6E6;
  display: flex;
  justify-content: center;
  padding-bottom: 16px;
}
.fm_stories_content {
  width: 100%;
  padding: 64px 0 128px;
  background-color: #E6E6E6;
}

.fm_stories_articles {
  display: flex;
  justify-content: space-between;
  gap: 46px;
  margin-bottom: 120px;
}

.fm_stories_items {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.fm_stories_item {
  display: flex;
  gap: 32px;
  width: 48%;
  min-height: 256px;
  margin-bottom: 30px;
}

.fm_stories_item_image_holder {
  max-width: 392px;
  width: 100%;
}
.fm_stories_item_image {
  width: 100%;
  height: 100%;
}
.fm_stories_item_image img{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}

.fm_stories_item_infos {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  color: #333333;
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
}
.fm_stories_item_state {
  font-size: 20px;
  color: #FFD51C;
  background-color: #333333;
  padding: 5px 16px;
  border-radius: 13px;
  display: inline-block;
}
.fm_stories_item_title,
.fm_stories_item_title a {
  font-size: 30px;
  color: inherit;
  text-decoration: none;
  transition: all .3s ease;
}
.fm_stories_item_date {
  font-size: 20px;
  font-weight: 300;
}
.fm_stories_item_read_time{
  font-size: 20px;
  color: #FFD000;
  font-weight: 300;
}

.fm_see_all_articles {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.fm_see_all_articles h2 {
  font-family: 'Covered By Your Grace', cursive;
  font-size: 49px;
  color: #333333;
  margin-bottom: 12px;
}
.fm_see_all_articles img{
  margin-bottom: 38px;
}
.fm_see_all_articles a {
  padding: 21px 28px;
  background-color: #FFD51C;
  color: #333333;
  font-family: 'Roboto Mono', monospace;
  font-weight: 400;
  font-size: 25px;
  border-radius: 17px;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 6px;
  transition: all .3s ease;
}


/* hover */
@media (hover:hover) {
  .fm_stories_single_title:hover{
    opacity: 0.8;
  }
  .fm_see_all_articles a:hover{
    opacity: 0.8;
  }
}

/* MEDIA */
@media only screen and (max-width: 1366px) {
  .fm_stories_item {
    flex-direction: column;
  }
  .fm_stories_item_image_holder {
    max-width: 100%;
  }
  .fm_stories_item_image {
    padding-top: 56.25%;
  }
}

@media only screen and (max-width: 680px) {
  .fm_see_all_articles h2 {
    font-size: 6vw;
  }
  .fm_see_all_articles img{
    width: 100%;
  }
}

@media only screen and (max-width: 640px) {
  .fm_stories_items {
    flex-direction: column;
    gap: 64px;
  }
  .fm_stories_item {
    width: 100%;
    margin: 0;
  }
}